<template>
    <div class="section-newsletter">
        <div class="container">
            <div class="s-left">
                <img
                    src="/news_largeIcon.svg">
                <h1>{{ $t('newsletter.title') }}</h1>
            </div>
            <div class="s-right">
                
                <div v-if="!sended">
                    {{ $t('newsletter.content') }}
                    <div class="newsletter-form">
                        <input
                            :placeholder="$t('newsletter.placeholder')"
                            v-model="email">
                        <button @click="sign">{{ $t('newsletter.sign') }}</button>
                    </div>
                </div>
                <div v-else>
                    {{ $t('newsletter.success') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: () => ({
        sended: false
    }),
    computed: {
        email: {
            get() {
                return this.$store.state.newsletter.email
            },
            set( value ){
                this.$store.commit( 'newsletter/setEmail', value )
            }
        }
    },
    methods: {
        sign(){
            this.$store.dispatch( 'newsletter/sign' )
            this.email = ''
            this.sended = true
            setTimeout(function(){
                this.sended = false
            }.bind(this), 10000)
        }
    }
}
</script>