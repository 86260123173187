<template>
  <div class="file-manager" ref="container">
    <div class="file-manager__column" v-if="search">
      <div class="file-manager__column__item">
        <div class="file-manager__search">
          <div class="file-manager__search__title">
            {{ $t('box.search') }}
          </div>
          <div class="file-manager__search__actions">
            <a
              class="file-manager__search__button"
              href="#"
              @click.prevent="$emit('clearSearch')"
            >
              {{ $t('box.clearSearch') }}
            </a>
          </div>
          <div
            class="file-manager__search__no-results"
            v-if="!isLoading && folders.length === 0"
          >
            {{ $t('box.noResults') }}
          </div>
        </div>
      </div>
    </div>
    <div class="file-manager__column" v-for="(column, index) in folders" :key="index">
      <div class="file-manager__column__item" v-for="item in column" :key="item.id">
        <file-manager-folder
          v-if="item.type === 'folder'"
          :folder="item"
          :is-selected="checkSelected(item)"
          @select="$emit('select', { item, index })"
          @open="$emit('open', item)"
        />
        <file-manager-file
          v-if="item.type === 'file'"
          :file="item"
          :is-downloading="checkDownloading(item)"
          @download="$emit('download', item)"
          @open="$emit('open', item)"
        />
      </div>
    </div>
    <div class="file-manager__column" v-if="isLoading">
      <div class="file-manager__column__item file-manager__column__item--spinner">
        <spinner />
      </div>
    </div>
  </div>
</template>

<script>
import FileManagerFolder from './FileManagerFolder'
import FileManagerFile from './FileManagerFile'
import Spinner from './Spinner'
export default {
  props: {
    folders: Array,
    currentFolderPath: Array,
    isLoading: Boolean,
    downloading: Number,
    search: String
  },
  components: {
    FileManagerFolder,
    FileManagerFile,
    Spinner
  },
  methods: {
    checkSelected(item) {
      return this.currentFolderPath.includes(item.id)
    },
    checkDownloading(item) {
      return this.downloading === +item.id
    },
    moveScrollToRight() {
      this.$refs.container.scrollTo({
        left: this.$refs.container.scrollWidth,
        behavior: 'smooth'
      })
    }
  },
  watch: {
    isLoading: function (value) {
      if (!value) {
        this.$nextTick(this.moveScrollToRight)
      }
    }
  }
}
</script>
