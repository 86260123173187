<template>
  <div :class="classes" @click.prevent="$emit('select', folder)">
    <div class="file-manager-folder__name">
      {{ folder.name }}
    </div>
    <div class="file-manager-folder__icon" @click.stop="$emit('open', folder)">
      <img src="/external-link.svg" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    folder: Object,
    isSelected: Boolean
  },
  computed: {
    classes() {
      return {
        'file-manager-folder': true,
        'file-manager-folder--selected': this.isSelected
      }
    }
  }
}
</script>
