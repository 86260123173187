<template>
    <div v-if="items.length" class="section-events">
        <div class="container">
            <div class="section-title">
                {{ titleLabel }}
            </div>
            <div
                class="items"
                :class="[this.lengthClass]"
                v-if="itemsLength < 4">
                <div class="event"
                    v-for="(item,index) in items"
                    @click="goToEvent(item.id)"
                    :key="index">
                    <img :src="`${ENVS.API_URL}images/${item.customFields.image}`" />
                    <div class="event-inner">
                        <div class="event-title">
                            {{item.title}}
                        </div>
                        <div class="event-description">
                            {{item.description}}
                        </div>
                    </div>
                </div>
            </div>

            <div v-else>
                <div class="multi-items" :key="carouselReload">
                    <carousel
                        :perPage="3"
                        :autoplay="true"
                        :autoplayTimeout="3000"
                        :paginationEnabled="true"
                        :navigationEnabled="true"
                        :loop="true">
                        <slide
                            v-for="(item, index) in items"
                            :key="index">
                            <div class="event"
                                @click="goToEvent(item.id)">
                                <img :src="`${ENVS.API_URL}images/${item.customFields.image}`" />
                                <div class="event-inner">
                                    <div class="event-title">
                                        {{item.title}}
                                    </div>
                                    <div class="event-description">
                                        {{item.description}}
                                    </div>
                                </div>
                            </div>
                        </slide>
                    </carousel>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ENVS from '../../env'
import { Carousel, Slide } from 'vue-carousel'

export default {
    props: {
        typeName: String,
    },
    components: {
        Carousel,
        Slide
    },
    data: () => ({
        ENVS,
        carouselReload: 0
    }),
    computed: {
        items(){
            return this.$store.state[this.typeName].list
                .map( item => {
                    item['title'] = item.fields.title[this.$i18n.locale.toUpperCase()]
                    item['description'] = item.fields.description[this.$i18n.locale.toUpperCase()]
                    return item
                })
        },
        itemsLength(){
            return this.items.length
        },
        lengthClass(){
            var cssClass = 'multiple'

            switch(this.itemsLength){
                case 1:
                    cssClass = 'single'
                    break        
                case 2:
                    cssClass = 'duo'
                    break        
                case 3:
                    cssClass = 'tri'
                    break     
            }

            return cssClass
        },
        titleLabel() {
            return this.$t(`${this.typeName}.title`)
        }
    },
    created(){
        this.getData()
    },
    methods: {
        async getData(){
            await this.$store.dispatch( `${this.typeName}/list` )
        },
        goToEvent( id ){
            this.$router.push({
                name: 'pages-view',
                params: { id }
            })
        }
    }
}
</script>
