<template>
  <div id="pages-home">
    <section-highlights type-name="highlights" />
    <section-verticals type-name="verticals" />
    <section-box-file-manager type-name="box" />
    <section-events type-name="events" />
    <section-verticals type-name="materials" />
    <section-highlights type-name="calendars" />
    <section-highlights type-name="institutionals" />
    <section-verticals type-name="saletools" />
    <section-highlights type-name="tickets" />
    <section-tools />
    <section-newsletter />
    <section-contact />
  </div>
</template>

<script>
import SectionHighlights from '../../components/SectionHighlights.vue'
import SectionTools from '../../components/SectionTools.vue'
import SectionVerticals from '../../components/SectionVerticals.vue'
import SectionEvents from '../../components/SectionEvents.vue'
import SectionNewsletter from '../../components/SectionNewsletter.vue'
import SectionContact from '../../components/SectionContact.vue'
import SectionBoxFileManager from '../../components/SectionBoxFileManager.vue'

export default {
  components: {
    SectionHighlights,
    SectionTools,
    SectionVerticals,
    SectionEvents,
    SectionNewsletter,
    SectionContact,
    SectionBoxFileManager
  }
}
</script>
