<template>
    <div class="upload-box">
        <div class="up-btn" @click="pickFile" v-if="!file">
            Enviar um arquivo
        </div>
        <div class="up-file" v-else>
            <div class="up-file-title">
               {{file}}
            </div>
            <div class="up-file-close" @click="clear">
                <img src="/close.svg" />
            </div>
        </div>
        <input type="file" ref="inputFile" @change="selectFile" style="display: none;" />
    </div>
</template>

<script>
export default {
    props: [ 'value' ],
    data: () => ({
        file: null,
    }),
    methods: {
        clear() {
            this.$refs.inputFile.value = null
            this.file = null
            this.$emit( 'input', null )
        },
        selectFile( e ){
            this.file = e.target.files[0].name
            this.$emit( 'input', e.target.files[0] )
        },
        pickFile(){
            this.$refs.inputFile.click()
        }
    }
}
</script>