<template>
  <div class="section-verticals">
    <div class="container">
      <div class="section-title">
        {{ titleLabel }}
      </div>
      <div class="section-search">
        <input
          v-model="model.search"
          placeholder="Filtre os arquivos"
          class="section-search__input"
          @keyup.enter="search"
        />
        <button type="button" @click.prevent="search" class="section-search__button">
          Buscar
        </button>
      </div>
      <file-manager
        :folders="folders"
        :currentFolderPath="currentFolderPath"
        :is-loading="isLoading"
        :downloading="downloading"
        :search="boxSearch"
        @select="onSelect"
        @download="onDownload"
        @clearSearch="onClearSearch"
        @open="onOpen"
      />
    </div>
  </div>
</template>

<script>
import ENVS from '../../env'
import FileManager from './FileManager'

export default {
  props: {
    typeName: String
  },
  components: {
    FileManager
  },
  data: () => ({
    model: {
      search: ''
    },
    columnIndex: 0
  }),
  computed: {
    items() {
      return this.$store.state[this.typeName].list.map((item) => {
        return item
      })
    },
    titleLabel() {
      return this.$t(`${this.typeName}.title`)
    },
    folders() {
      return this.items
    },
    currentFolderPath() {
      return this.$store.state.box.currentFolderPath
    },
    isLoading() {
      return this.$store.state.box.loading
    },
    downloading() {
      return this.$store.state.box.downloading
    },
    boxSearch() {
      return this.$store.state.box.search
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData(id = null) {
      const { columnIndex } = this
      this.$store.dispatch(`${this.typeName}/list`, { columnIndex, id })
    },
    onSelect({ item, index }) {
      if (!this.$store.state.box.loading) {
        this.columnIndex = index
        this.$nextTick(() => {
          this.getData(item.id)
        })
      }
    },
    async onDownload({ id }) {
      await this.$store.dispatch(`${this.typeName}/download`, +id)
      this.$nextTick(() => {
        if (this.$store.state.box.downloads[id]) {
          document.location = this.$store.state.box.downloads[id]
        }
      })
    },
    async search() {
      if (!this.$store.state.box.loading) {
        if (this.model.search.trim().length >= 2) {
          if (this.model.search !== this.boxSearch) {
            await this.$store.dispatch(`${this.typeName}/search`, this.model.search)
          }
        } else if (!this.model.search && this.boxSearch) {
          this.$store.dispatch(`${this.typeName}/resetSearch`)
          this.columnIndex = 0
          this.getData()
        }
      }
    },
    onClearSearch() {
      this.model.search = ''
      this.$store.dispatch(`${this.typeName}/resetSearch`)
      this.columnIndex = 0
      this.getData()
    },
    onOpen(item) {
      const url = `${ENVS.API_URL}${item.url}`
      window.open(url)
    }
  }
}
</script>
