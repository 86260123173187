<template>
  <div :class="classes">
    <file-manager-file-thumb :file="file"></file-manager-file-thumb>
    <div class="file-manager-file__content">
      <h2 class="file-manager-file__name">{{ file.name }}</h2>
      <div class="file-manager-file__actions">
        <a
          v-if="!isDownloading"
          class="file-manager-file__button"
          href="#"
          @click.prevent="$emit('download', file)"
        >
          <img src="/download.svg" />
        </a>
        <a
          v-if="!isDownloading"
          class="file-manager-file__button"
          href="#"
          @click.prevent="$emit('open', file)"
        >
          <img src="/external-link.svg" />
        </a>
        <spinner v-if="isDownloading" />
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from './Spinner'
import FileManagerFileThumb from './FileManagerFileThumb'
export default {
  props: {
    file: Object,
    isDownloading: Boolean
  },
  components: {
    Spinner,
    FileManagerFileThumb
  },
  computed: {
    classes() {
      return {
        'file-manager-file': true,
        'file-manager-file--downloading': this.isDownloading
      }
    },
    extension() {
      const extension = this.file.name.split('.').pop()
      if (extension) {
        return extension.toUpperCase()
      }
      return '?'
    }
  }
}
</script>
