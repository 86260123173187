<template>
    <div class="section-tools">
        <div class="container">
            <div
                class="items">
                <div class="tool" @click="goTo('https://flukevsa.com/')">
                    <img src="TOOLS_01.png" />
                </div>
                <div class="tool" @click="goTo('https://flukeacademy.com.br/')">
                    <img src="TOOLS_02.png" />
                </div>
                <div class="tool" @click="goTo('https://cursos.flukeacademy.com/')">
                    <img src="TOOLS_03.png" />
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    methods: {
        goTo( URL ){
            window.open( URL, '_blank' )
        }
    }
}
</script>
