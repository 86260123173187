<template>
    <div v-if="items.length" class="section-verticals">
        <div class="container">
            <div class="section-title">
                {{ titleLabel }}
            </div>
            <div v-if="items.length > 4">
                <carousel
                    :per-page="4"
                    :autoplay="true"
                    :autoplayTimeout="6000"
                    :navigationEnabled="true"
                    :paginationEnabled="false"
                    :loop="true"
                    class="items_carousel">
                    <slide v-for="(item,index) in items" :key="index">
                        <div class="vertical"
                            @click="goTo(item.id)">
                            <img :src="`${ENVS.API_URL}images/${item.customFields.image}`" />
                            <div class="title">
                                {{item.title}}
                            </div>
                        </div>
                    </slide>
                </carousel>
            </div>
            <div class="items" v-else>
                <div class="vertical"
                    v-for="(item,index) in items"
                    @click="goTo(item.id)"
                    :key="index">
                    <img :src="`${ENVS.API_URL}images/${item.customFields.image}`" />
                    <div class="title">
                        {{item.title}}
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import ENVS from '../../env'
import { Carousel, Slide } from 'vue-carousel'

export default {
    props: {
        typeName: String,
    },
    components: {
        Carousel,
        Slide
    },
    data: () => ({
        ENVS,
        carouselReload: 0
    }),
    computed: {
        items(){
            return this.$store.state[this.typeName].list
                .map( item => {
                    item.title = item.fields.title[this.$i18n.locale.toUpperCase()]
                    return item
                })
        },
        titleLabel() {
            return this.$t(`${this.typeName}.title`)
        }
    },
    created(){
        this.getData()
    },
    methods: {
        getData(){
            this.$store.dispatch( `${this.typeName}/list` )
        },
        goTo( id ){
            this.$router.push({
                name: 'pages-view',
                params: { id }
            })
        }
    }
}
</script>

<style lang="scss">
.section-verticals .items_carousel .vertical {
    padding: 8%;
    position: relative;
    height: 220px;
    cursor: pointer;
}
.section-verticals .items_carousel .vertical .title {
    background-color: #FCC211;
    padding: 5px 10px;
    margin-top: -32px;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>