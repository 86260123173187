<template>
    <div v-if="items.length" class="section-highlights" :style="backgroundImage">
        <div class="container" :key="carouselReload">
            <carousel
                @page-change="page"
                :perPage="1"
                :autoplay="true"
                :autoplayTimeout="6000"
                :paginationEnabled="false"
                :navigationEnabled="true"
                :loop="true">
                <slide
                    v-for="(item, index) in items"
                    :key="index">
                    <div class="s-left">
                        <img v-if="item.image != false" :src="`${ENVS.API_URL}images/${item.image}`" />
                    </div>
                    <div class="s-right">
                        <h1>{{item.title}}</h1>
                        <p>{{item.description}}</p>
                        <div class="btn" @click="goTo(item.id)">
                            <button>{{ buttonLabel }}</button>
                        </div>
                    </div>
                </slide>
            </carousel>
        </div>
    </div>
</template>

<script>
import ENVS from '../../env'
import { Carousel, Slide } from 'vue-carousel'

export default {
    props: {
        typeName: String,
    },
    components: {
        Carousel,
        Slide
    },
    data: () => ({
        ENVS,
        carouselReload: 0,
        backgroundImage: ''
    }),
    computed: {
        items(){
            return this.$store.state[this.typeName].list
                .map( item => {
                    if( 
                        typeof(item.customFields) != 'undefined' &&
                        item.customFields.image
                    ) {
                        item['image'] = item.customFields.image
                    } else {
                        item['image'] = false
                    }

                    item['title'] = item.fields.title[this.$i18n.locale.toUpperCase()]
                    item['description'] = item.fields.description[this.$i18n.locale.toUpperCase()]
                    return item
                })
        },
        buttonLabel() {
            return this.$t(`${this.typeName}.more`)
        }
    },
    created(){
        this.getData()
    },
    methods: {
        async getData(){
            await this.$store.dispatch( `${this.typeName}/list` )
            this.page(0)
        },
        page( num ){
            if( this.items[num] && this.items[num].image ){
                this.backgroundImage = `background-image:url(${ENVS.API_URL}images/${this.items[num].image}`
            } else {
                this.backgroundImage = ''
            }
        },
        goTo( id ){
            this.$router.push({
                name: 'pages-view',
                params: { id }
            })
        }
    }
}
</script>
