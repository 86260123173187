<template>
    <div class="section-contact">
        <div class="container">
            <div class="section-title">
                {{ $t('contact.title') }}
            </div>
            <div class="section-par">
                {{ $t('contact.content') }}
            </div>
            <div v-if="!sended" key="refresh">
                <input type="text" 
                    :placeholder="$t('contact.form.name')" 
                    v-model="contact.name" /> <br/>
                <input type="text" 
                    :placeholder="$t('contact.form.email')" 
                    v-model="contact.email" /> <br/>
                <textarea 
                    :placeholder="$t('contact.form.message')" 
                    v-model="contact.message" /> <br/>
                <f-upload-box v-model="contact.file" />
                <button @click="send">{{ $t('contact.form.btn') }}</button>
            </div>
            <div v-if="sended">
                {{ $t('contact.success') }}
            </div>
        </div>
    </div>
</template>

<script>
import FUploadBox from './UploadBox.vue'

export default {
    components: {
        FUploadBox
    },
    data: () => ({
        refresh: 0,
        sended: false
    }),
    computed: {
        contact(){
            return this.$store.state.contact.data
        }
    },
    methods: {
        send(){
            this.$store.dispatch( 'contact/send' )
            this.sended = true

            this.contact.name = ''
            this.contact.email = ''
            this.contact.message = ''

            this.refresh++

            setTimeout(function(){
                this.sended = false
            }.bind(this), 10000)
        }
    }
}
</script>